<template>
  <BaseCard>
    <header class="sm:flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            icon="Team"
            width="24px"
            height="24px"
            view-box="0 0 80.13 80.13"
          >
            <Team />
          </Icon>
        </span>
        <h2 class="text-lg">
          Your team
        </h2>
      </div>
      <AddTeamMemberButton
        class="sm:text-right mt-4 sm:mt-0"
        @createdTeamMember="fetchTeamMembers()"
      />
    </header>

    <TeamTable
      v-if="members.length > 0"
      :columns="columns"
      :table-data="members"
      class="mt-8"
    />
    <TeamEmpty
      v-if="members.length === 0 && !isLoading"
    />
    <div
      v-if="isLoading"
      class="p-16"
    >
      <Loader />
    </div>
  </BaseCard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Team from '@components/Icons/Team'
import AddTeamMemberButton from '@components/Teams/AddTeamMemberButton'
import TeamTable from '@components/Teams/TeamTable'
import TeamEmpty from '@components/Teams/TeamEmpty'
import Loader from '@components/Loader'

import membersApi from '@api/members'
import { mapGetters } from 'vuex'

export default {
  components: {
    AddTeamMemberButton,
    Icon,
    Team,
    TeamTable,
    TeamEmpty,
    Loader
  },

  data() {
    return {
      isLoading: false,
      members: [],
      columns: ['name', 'email']
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  created() {
    this.fetchTeamMembers()
  },

  methods: {
    /**
     * Fetch the team members for the organisation
     */
    fetchTeamMembers() {
      this.isLoading = true

      return membersApi.index(this.organisationId)
        .then(members => {
          this.members = members
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          throw error
        })
    }
  }
}
</script>
